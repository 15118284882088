<template>
  <div class="container">
    <div class="column">
      <h2 class="subtitle has-text-light">
        Dekking
      </h2>

      <!-- for products that have choices -->
      <template v-if="product.coverageChoices">
        <h1 class="title has-text-light">
          Hoe wil je graag verzekerd zijn?
        </h1>
        <div class="columns is-multiline">
          <div
            v-for="coverage in coverageChoices"
            :key="coverage.key"
            class="column"
          >
            <a @click="choice = coverage.key">
              <Cardslot
                class="mouseover"
                :class="{ green: coverage.key == choice }"
              >
                <template #header>
                  {{ coverage.name }}
                </template>
                <template #content>
                  <div class="has-text-centered">
                    
                    <b-icon
                      size="is-large"
                      type="is-light"
                      :icon="coverage.icon"
                    /> 
                 
                  </div>
                </template>
                <template #footer>
                  <p>{{ coverage.description }}</p> <br>
                  <b-radio
                    v-model="choice"
                    class="has-text-centered"
                    :native-value="coverage.key"
                    type="is-green"
                  >
                    <p
                      v-if="coverage.key == choice"
                      class="has-text-light"
                    > mijn keuze </p>
                  </b-radio>
              
                </template>
              </Cardslot>
            </a>
          </div>
        </div>
        <a
          v-if="choice"
          @click="
            updateregistration({
              step: step,
              registrationdata: {
                coverageChoice: choice,
              },
              route: $route.path,
            });
          "
        > 
          <b-icon
            pack="fas"
            icon="chevron-down"
            size="is-large"
            type="is-light"
          />
          <span class="has-text-light">Dit is mijn keuze</span>
          <!-- <p class="has-text-light">Ga naar de volgende stap</p>  -->
        </a>
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Cardslot from "../shared/cardslot.vue";

export default {
  components: {
    Cardslot,
  },
  data() {
    return {
      choice: "",
      coverageChoices: "",
    };
  },
  computed: {
    ...mapGetters([
      "product",
      "allCoverageChoices",
    ]),
    step() {
      const product = this.product.key.split("Insurance")[0];
      return `${product}CoverageChoice`;
    },
  },
  mounted() {
    //// map checked on coveragechoices
    const choices = this.product.coverageChoices;
    if (choices) {
      this.coverageChoices = choices.map((x) => {
        // console.log(this.allCoverages)
        return this.allCoverageChoices && this.allCoverageChoices.indexOf(x.key) > -1
          ? { ...x, checked: true }
          : { ...x, checked: false };
      });
      if (!this.coverageChoices.filter((x) => x.checked)[0]) {
        this.choice = choices.filter((x) => x.advice)[0].key;
      } else {
        this.choice = this.coverageChoices.filter((x) => x.checked)[0].key;
      }
    }
  },
  methods: {
    ...mapActions(["updateregistration", "toNextRoute", "getProductsStatus"]),
    // ...mapMutations(["updateAllCoverages"])
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/styles/variables.scss";

.green {
  border-width: 1px;
  border-style: solid;
  border-color: $green;
}

// .card {
//   cursor: pointer;
// }
</style>

